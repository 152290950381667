const numberClasses = [{
        "id": 2,
        "value": "silver",
        "label": "search.class_silver",
        "label_c": "search.class_silver_c",
        "label_l": "search.class_silver_l",
        "label_alt": "search.class_silver_alt",
        "cost": "300",
        "img": "silver_class.svg",
        "img_alt": "silver_class.svg",
        "order": 1
    },
    {
        "id": 66,
        "value": "silver_spec",
        "label": "search.class_silver_spec",
        "label_c": "search.class_silver_spec_c",
        "label_l": "search.class_silver_spec_l",
        "label_alt": "search.class_silver_spec_alt",
        "cost": "500",
        "img": "silver_class.svg",
        "img_alt": "silver_class.svg",
        "order": 5
    },
    {
        "id": 3,
        "value": "gold",
        "label": "search.class_gold",
        "label_c": "search.class_gold_c",
        "label_l": "search.class_gold_l",
        "label_alt": "search.class_gold_alt",
        "cost": "3000",
        "img": "gold_class.svg",
        "img_alt": "gold_class.svg",
        "order": 2
    },
    {
        "id": 67,
        "value": "gold_spec",
        "label": "search.class_gold_spec",
        "label_c": "search.class_gold_spec_c",
        "label_l": "search.class_gold_spec_l",
        "label_alt": "search.class_gold_spec_alt",
        "cost": "5000",
        "img": "gold_class.svg",
        "img_alt": "gold_class.svg",
        "order": 6
    },
    {
        "id": 1,
        "value": "standard",
        "label": "search.class_stand",
        "label_c": "search.class_stand",
        "label_l": "search.class_stand_l",
        "label_alt": "search.class_stand_alt",
        "cost": "0",
        "img": "standart_class.svg",
        "img_alt": "standart_class.svg",
        "order": 4
    },
    {
        "id": 4,
        "value": "exclus",
        "label": "search.class_exclus",
        "label_c": "search.class_exclus_c",
        "label_l": "search.class_exclus_l",
        "label_alt": "search.class_exclus_alt",
        "cost": "7500",
        "img": "exclus_class.svg",
        "img_alt": "exclus_class.svg",
        "order": 3
    }

];
export default numberClasses
