import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from '../i18n'
import { isMobile } from "mobile-device-detect";

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        component: () =>
            import ('../views/Init.vue'),
    },
    {
        path: '/init/:lang/',
        name: 'Init',
        component: () =>
            import ('../views/Init.vue'),
        props: true,
    },
    {
        path: '/search',
        name: 'NumberSearch',
        component: () =>
            import ('../views/NumberSearch.vue'),
        meta: { transitionName: 'fade' },
        beforeEnter: (to, from, next) => {
            if (isMobile) {
                next({ name: 'NumberSearchMobile' });
            } else next();
        }
    },
    {
        path: '/info/:msisdn',
        name: 'NumberInfo',
        component: () =>
            import ('../views/DesktopNumberInfo.vue'),
        props: true,
        meta: { transitionName: 'fade' },
    },
    {
        path: '/search-mobile',
        name: 'NumberSearchMobile',
        component: () =>
            import ('../views/NumberSearchMobile.vue'),
        meta: { transitionName: 'fade' },
    },
    {
        path: '/info-mobile/:msisdn',
        name: 'NumberInfoMobile',
        component: () =>
            import ('../views/NumberInfoMobile.vue'),
        props: true,
        meta: { transitionName: 'fade' },
    },
    {
        path: '/change-mobile',
        name: 'NumberChangeMobile',
        component: () =>
            import ('../views/NumberChangeMobile.vue'),
        props: true,
        meta: { transitionName: 'fade' },
    },
    {
        path: '/reserve-mobile',
        name: 'NumberReserveMobile',
        component: () =>
            import ('../views/NumberReserveMobile.vue'),
        props: true,
        meta: { transitionName: 'fade' },
    },
    {
        path: '/restricred-actions-mobile',
        name: 'RestricredActionsMobile',
        component: () =>
            import ('../views/RestricredActionsMobile.vue'),
        props: true,
        meta: { transitionName: 'fade' },
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve({ x: 0, y: 0 })
            }, 500)
        })
    }
})

router.beforeEach((to, from, next) => {
    document.title = i18n.t("nav.search_number") + " :: Mega";
    const IsItABackButton = window.popStateDetected;
    window.popStateDetected = false;

    if (IsItABackButton && from.name === "NumberInfoMobile") next({ name: 'NumberSearchMobile' });
    else next();
})

export default router
