import axios from 'axios';
import Vue from 'vue';
import Toasted from 'vue-toasted';
Vue.use(Toasted);

const instance = axios.create({
    baseURL: process.env.VUE_APP_API_ENDPOINT,
    // headers: {
    //     'Content-Type': 'application/x-www-form-urlencoded'
    // },
    timeout: 60000
});

instance.interceptors.response.use(function(response) {
    return response;
}, function(error) {
    const statusCode = error.response ? error.response.status : '';
    let message = `Извините, произошла системная ошибка. Повторите попытку позже`;
    if (statusCode == 503) {
        message = `Извините, сервис временно недоступен`;
    }

    Vue.prototype.$toasted.error(message, {
        theme: "toasted-primary",
        position: "top-center",
        duration: 10000,
    });

    return Promise.reject(error);
});

export default instance;