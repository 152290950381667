<template>
  <div id="app">
    <transition-page>
      <router-view />
    </transition-page>
  </div>
</template>

<script>
import TransitionPage from "./transitions/TransitionPage.vue";

export default {
  created() {
    window.popStateDetected = false;
    window.onpopstate = function () {
      window.popStateDetected = true;
    };
  },
  components: {
    TransitionPage,
  },
};
</script>

<style>
@import "assets/index.css";
</style>

