import Vue from 'vue'
import Vuex from 'vuex'
import numberClasses from "@/utils/numberClasses";
import API from '@/api';
import displayLimits from "../utils/displayLimits";
//const querystring = require('querystring');

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        numberClasses: numberClasses,
        filterParams: {},
        showResults: false,
        searchResults: [],
        limitData: {},
        selectedClassId: null,
        isMega24: 0,
        promo995: 0,
        numberClasse: 1,
        isNoUpdate: false,
        showMoreButton: false
    },
    mutations: {
        SET_FILTER_PARAMS(state, data) {
            state.filterParams = data;
        },
        SET_FILTER_SEARCH_TYPE(state, data) {
            Vue.set(state.filterParams, "searchType", data);
        },
        SET_FILTER_MASK(state, data) {
            Vue.set(state.filterParams, "mask", data);
        },
        SET_SHOW_RESULTS(state, data) {
            state.showResults = data;
        },
        SET_SEARCH_RESULTS(state, data) {
            state.searchResults = data;
        },
        SET_LIMIT_DATA(state, data) {
            Vue.set(state.limitData, data.classId, data.count);
        },
        SET_LIMIT_DATA_DEFAULT(state, data) {
            for (const [key] of Object.entries(state.limitData)) {
                Vue.set(state.limitData, key, data);
            }
        },
        SET_SELECTED_CLASS_ID(state, data) {
            state.selectedClassId = data;
        },
        SET_MEGA24(state, data) {
            state.isMega24 = data;
        },
        SET_PROMO_995(state, data) {
            state.promo995 = data;
        },
        SET_CLASS(state, data) {
            state.currentNumberClass = data;
        },
        SET_PAGE(state, data) {
            state.currentPage = data;
        },
        SET_NO_UPDATE(state, data) {
            state.isNoUpdate = data;
        },
        SET_SHOW_MORE_BUTTON(state, data) {
            state.showMoreButton = data;
        }
    },
    actions: {
        async fetchSearchedNumbers({ commit, getters }, data) {
            // commit('SET_SHOW_RESULTS', false);
            console.log('xxx5')
            const filter = getters.filterParams;
            const queryParams = {
                categories: filter.classes.join(','),
                //token: data.token,
                ismob: data.ismob,
            };

            if(!data.inc)
                commit('SET_NO_UPDATE', true);

            if (process.env.NODE_ENV === 'development') {
                queryParams.ipaddress = "10.244.10.39"
            }

            let innerMask = '';

            if (filter.searchType === "number") {
                let numberParams = filter.numberParams;
                let numberPieces = numberParams.numberPieces;
                let p1 = numberPieces.p1 === "" ? "*" : numberPieces.p1;
                let p2 = numberPieces.p2 === "" ? "*" : numberPieces.p2;
                let p3 = numberPieces.p3 === "" ? "*" : numberPieces.p3;
                let p4 = numberPieces.p4 === "" ? "*" : numberPieces.p4;
                let p5 = numberPieces.p5 === "" ? "*" : numberPieces.p5;
                let p6 = numberPieces.p6 === "" ? "*" : numberPieces.p6;

                queryParams.msisdn_code = numberParams.code;
                queryParams.msisdn_mask = `${p1}${p2}${p3}${p4}${p5}${p6}`;
                innerMask = queryParams.msisdn_mask.replaceAll('*', '_');
            } else {
                queryParams.msisdn_code = filter.favParams.code;
                queryParams.favorite_number = filter.favParams.value;
                innerMask = 'X' + queryParams.favorite_number + 'X';
            }

            let mask = '996';
            mask += (+queryParams.msisdn_code === -1) ? '___' : queryParams.msisdn_code;
            mask += innerMask;

            let cls = getters.selectedClassId || 1;

            let page = 1;
            if(data.inc) {
                page = Math.ceil(getters.searchResults.length / displayLimits.LIMIT_ITEMS) + 1;
            }

            let params = {
                cat: numberClasses.find(v => v.id === cls).id,
                act: 'mask',
                limit: displayLimits.LIMIT_ITEMS,
                page: page,
                mask: mask
            };

            let query = new URLSearchParams(params).toString();

            const results = getters.searchResults;
            const response = await API.get('?' + query);

            commit('SET_PAGE', params.page);
            commit('SET_SEARCH_RESULTS', !data.inc ? response.data : [ ...results, ...response.data ] );
            commit('SET_SHOW_MORE_BUTTON', response.data.length >= displayLimits.LIMIT_ITEMS);

            commit('SET_SHOW_RESULTS', true);
            commit('SET_SELECTED_CLASS_ID', cls);

        },

    },
    getters: {
        filterParams(state) {
            return state.filterParams;
        },
        showResults(state) {
            return state.showResults;
        },
        numberClass: (state) => (id) => {
            return state.numberClasses.find(num => num.id === id)
        },
        searchResults(state) {
            return state.searchResults;
        },
        msisdnInfo: (state) => (msisdn) => {
            return state.searchResults.find(num => num.MSISDN === msisdn)
        },
        numbersByClass: (state) => (/*id*/) => {
            return state.searchResults;
            /*
            return state.searchResults.filter(function(item) {
                return item.NCLS_ID.substring(0, 1) == id;
            });*/
        },
        limitData(state) {
            return state.limitData;
        },
        selectedClassId(state) {
            return state.selectedClassId;
        },
        isMega24(state) {
            return state.isMega24;
        },
        promo995(state) {
            return state.promo995;
        },
        currentNumberClass(state) {
            return state.currentNumberClass;
        },
        currentPage(state) {
            return state.currentPage;
        },
        noUpdate(state) {
            return state.isNoUpdate;
        },
        showMoreButton(state) {
            return state.showMoreButton;
        }
    },
})
