import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import i18n from './i18n'
import store from './store'
import VueLoading from 'vuejs-loading-plugin'
import { isMobile } from "mobile-device-detect";
import VueJsModal from 'vue-js-modal'

Vue.config.productionTip = false

const mobileCss = () =>
    import ('@/assets/mobile.css')
const desctopCss = () =>
    import ('@/assets/desktop.css')

if (isMobile) {
    Vue.use(VueLoading, { text: '' });
    mobileCss();
} else {
    desctopCss();
    Vue.use(VueLoading, { text: '', classes: ['loading_for_iframe'] });
}

Vue.use(VueJsModal, {
    dialog: true,
    dynamic: true
});

new Vue({
    store,
    router,
    i18n,
    render: h => h(App)
}).$mount('#app')